@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'

$heroImg: url('../../../assets/images/hero/home.png')
$logoImg: url('../../../assets/images/hero/bg-heading.svg')

.hero
    position: relative

    +desktop
        margin-top: 45px

    &__container
        position: relative
        z-index: 2

    &__blocks
        +desktop
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 50px

        +fullhd
            margin-left: 80px

    &__background
        +desktop
            max-width: 1920px
            margin: 0 auto
            left: 0
            right: 0
            z-index: 1
            position: absolute
            width: 100%
            height: 100%
            display: grid
            grid-template-columns: 1fr 1fr

            &::before
                content: ''

            div
                background-image: $heroImg
                background-repeat: no-repeat
                background-size: cover
                background-position: center

    &__image
        margin-right: -20px
        margin-top: 20px
        width: calc(100% + 20px)

        +desktop
            display: none

    &__hr
        margin: 25px 0
        border: 0
        border-top: 1px solid #C0C0C0

        +desktop
            max-width: 500px

    &__text
        &--title
            @extend .text--heading-big
            @extend .text--color-blue
            font-weight: 700
            background-color: #005CA9
            background-image: $logoImg
            background-size: 100%
            background-position: left -200px
            background-clip: text
            color: white
            -webkit-text-fill-color: transparent
            -webkit-background-clip: text

            margin: 0

            +touch
                font-size: 4.5rem
                padding: 40px 0
                line-height: 1.2

            +contrast
                color: $contrast-yellow !important
                background-color: transparent
                background-image: none
                background-size: 100%
                background-position: left -200px
                background-clip: unset
                -webkit-text-fill-color: $contrast-yellow !important
                -webkit-background-clip: unset

        &--content
            @extend .text--body
            @extend .text--color-dark
            margin-top: 25px

            +desktop
                margin-top: 75px
                max-width: 525px

        &--more
            @extend .text__link

    &__more
        +touch
                margin-bottom: 50px
