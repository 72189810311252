@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.collectionsList

    &__search
        padding-top: 125px !important
        
    &__content
        display: flex
        flex-direction: row
        flex-wrap: wrap
        gap: 20px
        background-color: #F5F5F5

        +contrast
            background-color: $contrast-black

        +widescreen
            display: grid
            grid-template-columns: minmax(0, 800px) minmax(0, 800px)

    &__item
        padding: 25px
        display: flex
        max-width: 800px
        gap: 32px
        background-color: #F9F9F9

        +contrast
            background-color: $contrast-black

        +touch 
            flex-direction: column
            width: 100%

        +mobile
            width: 100%

        +desktop-only
            width: auto

        +widescreen
            width: auto

        &--desc
            display: flex
            flex-flow: column
            justify-content: space-between

            +desktop-only
                width: auto

            +touch 
                width: 100%
                gap: 15px

            +widescreen
                width: 100%

        &--image 
            width: 255px
            height: 208px
            background-color: #F5F5F5
            background-repeat: no-repeat
            background-size: cover

        &--content
            line-height: 2rem

        &--header
            margin-bottom: 10px

        &--footer
            display: flex
            justify-content: end
            width: 800px
            gap: 30px

            +touch
                width: auto
                display: grid
                grid-template-columns: 1fr 1fr
                grid-template-rows: 1fr 1fr
                gap: 20px

            +desktop-only
                width: auto

            +widescreen
                width: 100%


    &__container
        padding-bottom: 50px

    &__head
        margin-top: 50px
        margin-bottom: 30px

        +desktop
            margin-top: 25px

        +mobile
            display: flex
            flex-direction: column-reverse
            margin-top: 0

        &--button 
            margin-top: 25px
            float: right

            +mobile
                float: none
                width: 100%
            
    &__content
        margin-top: 30px
        margin-bottom: 30px

        +desktop-only
            display: grid
            grid-template-columns: 1fr 1fr
            gap-x: 20px

    &__pagination
        text-align: right

    &__text
        &--title
            @extend .text--heading-main
            margin-top: 50px
            display: flex
            align-items: center
            gap: 25px

            span
                font-size: 1.75rem

            span:first-of-type
                font-weight: 500

            p 
                font-size: 1rem
                line-height: 1rem

            img
                +contrast
                    filter: contrast(100) invert(100%)

                +mobile
                    display: none

        &--head
            font-size: 1.25rem
            margin-top: 5px
            margin-bottom: 20px
            color: #005CA9

        &--counter      
            float: right
            margin-top: 4px
            color: #4A4A4A
            font-size: 0.9375rem

            +contrast
                color: $contrast-white

        &--date 
            line-height: 2rem

    &__show-more
        +touch
            grid-row-start: 1
            grid-row-end: 2
            grid-column-start: 1
            grid-column-end: 3
            
