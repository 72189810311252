﻿@font-face
  font-family: 'fw_100'
  src: url('./../assets/icons/fw_100.eot?43979120')
  src: url('./../assets/icons/fw_100.eot?43979120#iefix') format('embedded-opentype'), url('./../assets/icons/fw_100.woff2?43979120') format('woff2'), url('./../assets/icons/fw_100.woff?43979120') format('woff'), url('./../assets/icons/fw_100.ttf?43979120') format('truetype'), url('./../assets/icons/fw_100.svg?43979120#fw_100') format('svg')
  font-weight: normal
  font-style: normal

[class^='fw-icon-']::before, [class*=' fw-icon-']::before
  font-family: 'fw_100'
  font-style: normal
  font-weight: normal
  speak: none
  display: inline-block
  text-decoration: inherit
  width: 1em
  margin-right: .2em
  text-align: center
  font-variant: normal
  text-transform: none
  line-height: 1em
  margin-left: .2em
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale


.fw-icon-question::before
  content: '\e800'

.fw-icon-accept::before
  content: '\e801'

.fw-icon-accept-double::before
  content: '\e802'

.fw-icon-add::before
  content: '\e803'

.fw-icon-add-area::before
  content: '\e804'

.fw-icon-add-circle::before
  content: '\e805'

.fw-icon-add-reversed::before
  content: '\e806'

.fw-icon-arrow-double-left-full::before
  content: '\e807'

.fw-icon-arrow-double-right-full::before
  content: '\e808'

.fw-icon-arrow-down-full::before
  content: '\e809'

.fw-icon-arrow-down-long::before
  content: '\e80a'

.fw-icon-arrow-left::before
  content: '\e80b'

.fw-icon-arrow-left-full::before
  content: '\e80c'

.fw-icon-arrow-right::before
  content: '\e80d'

.fw-icon-arrow-right-full::before
  content: '\e80e'

.fw-icon-arrow-up-full::before
  content: '\e80f'

.fw-icon-arrow-up-long::before
  content: '\e810'

.fw-icon-bib::before
  content: '\e811'

.fw-icon-bold::before
  content: '\e812'

.fw-icon-book::before
  content: '\e813'

.fw-icon-book-add::before
  content: '\e814'

.fw-icon-book-locked::before
  content: '\e815'

.fw-icon-books::before
  content: '\e816'

.fw-icon-box::before
  content: '\e817'

.fw-icon-calendar::before
  content: '\e818'

.fw-icon-calendar2::before
  content: '\e819'

.fw-icon-calendar-checked::before
  content: '\e81a'

.fw-icon-chevron-double-left::before
  content: '\e81b'

.fw-icon-chevron-double-right::before
  content: '\e81c'

.fw-icon-chevron-down::before
  content: '\e81d'

.fw-icon-chevron-left::before
  content: '\e81e'

.fw-icon-chevron-right::before
  content: '\e81f'

.fw-icon-chevron-right-circle::before
  content: '\e820'

.fw-icon-chevron-up::before
  content: '\e821'

.fw-icon-close::before
  content: '\e822'

.fw-icon-close-circle::before
  content: '\e823'

.fw-icon-comment::before
  content: '\e824'

.fw-icon-comment2::before
  content: '\e825'

.fw-icon-compare::before
  content: '\e826'

.fw-icon-contrast::before
  content: '\e827'

.fw-icon-contrast-active::before
  content: '\e828'

.fw-icon-contrast-reversed::before
  content: '\e829'

.fw-icon-cookies::before
  content: '\e82a'

.fw-icon-dash-short::before
  content: '\e82b'

.fw-icon-dash-wide::before
  content: '\e82c'

.fw-icon-dataset::before
  content: '\e82d'

.fw-icon-datasets::before
  content: '\e82e'

.fw-icon-dot::before
  content: '\e82f'

.fw-icon-download::before
  content: '\e830'

.fw-icon-edit::before
  content: '\e831'

.fw-icon-external::before
  content: '\e832'

.fw-icon-eye::before
  content: '\e833'

.fw-icon-eye-disabled::before
  content: '\e834'

.fw-icon-facebook::before
  content: '\e835'

.fw-icon-files::before
  content: '\e836'

.fw-icon-files2::before
  content: '\e837'

.fw-icon-font::before
  content: '\e838'

.fw-icon-font-active::before
  content: '\e839'

.fw-icon-font-double-active::before
  content: '\e83a'

.fw-icon-font-reversed::before
  content: '\e83b'

.fw-icon-forward::before
  content: '\e83c'

.fw-icon-full-screen::before
  content: '\e83d'

.fw-icon-full-screen-close::before
  content: '\e83e'

.fw-icon-geolocation::before
  content: '\e83f'

.fw-icon-grid::before
  content: '\e840'

.fw-icon-hamburger::before
  content: '\e841'

.fw-icon-header::before
  content: '\e842'

.fw-icon-header2::before
  content: '\e843'

.fw-icon-header3::before
  content: '\e844'

.fw-icon-heart::before
  content: '\e845'

.fw-icon-home::before
  content: '\e846'

.fw-icon-image::before
  content: '\e847'

.fw-icon-image-close::before
  content: '\e848'

.fw-icon-image-geolocation::before
  content: '\e849'

.fw-icon-image-locked::before
  content: '\e84a'

.fw-icon-image-time::before
  content: '\e84b'

.fw-icon-info::before
  content: '\e84c'

.fw-icon-instagram::before
  content: '\e84d'

.fw-icon-italic::before
  content: '\e84e'

.fw-icon-like::before
  content: '\e84f'

.fw-icon-link2::before
  content: '\e850'

.fw-icon-link::before
  content: '\e851'

.fw-icon-list::before
  content: '\e852'

.fw-icon-list-end::before
  content: '\e853'

.fw-icon-locked::before
  content: '\e854'

.fw-icon-locked-circle::before
  content: '\e855'

.fw-icon-mail::before
  content: '\e856'

.fw-icon-more::before
  content: '\e857'

.fw-icon-page-description::before
  content: '\e858'

.fw-icon-page-number::before
  content: '\e859'

.fw-icon-question-reversed::before
  content: '\e85a'

.fw-icon-quote::before
  content: '\e85b'

.fw-icon-reload::before
  content: '\e85c'

.fw-icon-remove-reversed::before
  content: '\e85d'

.fw-icon-search::before
  content: '\e85e'

.fw-icon-search2::before
  content: '\e85f'

.fw-icon-search-cmwl::before
  content: '\e860'

.fw-icon-settings::before
  content: '\e861'

.fw-icon-settings2::before
  content: '\e862'

.fw-icon-settings3::before
  content: '\e863'

.fw-icon-share::before
  content: '\e864'

.fw-icon-solution::before
  content: '\e865'

.fw-icon-star::before
  content: '\e866'

.fw-icon-symbol::before
  content: '\e867'

.fw-icon-symbol2::before
  content: '\e868'

.fw-icon-time::before
  content: '\e869'

.fw-icon-to-end::before
  content: '\e86a'

.fw-icon-to-start::before
  content: '\e86b'

.fw-icon-transcription::before
  content: '\e86c'

.fw-icon-twitter::before
  content: '\e86d'

.fw-icon-unlocked::before
  content: '\e86e'

.fw-icon-verse-answer::before
  content: '\e86f'

.fw-icon-verse-auto-transcription::before
  content: '\e870'

.fw-icon-verse-clear::before
  content: '\e871'

.fw-icon-verse-comment::before
  content: '\e872'

.fw-icon-verse-delete::before
  content: '\e873'

.fw-icon-verse-down::before
  content: '\e874'

.fw-icon-verse-footer::before
  content: '\e875'

.fw-icon-verse-header::before
  content: '\e876'

.fw-icon-verse-move::before
  content: '\e877'

.fw-icon-verse-up::before
  content: '\e878'

.fw-icon-warning::before
  content: '\e879'

.fw-icon-zoom-in::before
  content: '\e87a'

.fw-icon-zoom-out::before
  content: '\e87b'
