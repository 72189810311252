@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

$mod1Img: url('../../../assets/images/modules/mod-1.svg')
$mod2Img: url('../../../assets/images/modules/mod-2.svg')
$mod3Img: url('../../../assets/images/modules/mod-3.svg')

.modules
    max-width: 1760px
    margin: auto
    padding-top: 45px
    padding-bottom: 45px
    overflow-x: hidden

    &__slider
        margin-top: 25px

    &__static
        display: block

        +touch
            display: none !important

    &__container
        padding-top: 45px
        padding-bottom: 45px

    &__slider-container
        display: flex
        justify-content: center
        text-align: center
        margin: auto
        max-width: 350px

    &__tiles
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        grid-gap: 10px
        margin-top: 30px

        +fullhd
            padding-right: 70px

    &__tile
        position: relative
        height: 393px
        padding-left: 0
        max-width: 600px

        +mobile-small
            max-width: 350px !important
            height: 420px

        +touch
            max-width: 550px

        +mobile
            margin: 0 10px

        &--content
            background-color: rgba(255, 255, 255, 0.95)
            max-width: 350px
            width: 66%
            display: grid
            padding: 23px 30px
            margin-top: 40px
            margin-left: 40px
            z-index: 1
            box-sizing: border-box
            min-height: 315px

            +contrast
                background-color: $contrast-black
                color: $contrast-white

            +touch
                margin-left: 40px
                width: 75%

            +mobile-small
                margin-left: -40px
                margin-top: 70px
                width: 140%
            //box-shadow: 0px 0px 10px 0px rgba(66, 68, 90, 0.3)

            &::before
                content: ''
                height: 100%
                display: block
                width: 50%
                position: absolute
                top: 0
                right: 0
                z-index: -1
                background-color: #0B8087
                background-image: $mod1Img
                background-repeat: no-repeat
                background-position: right
                background-size: contain

                +desktop
                    max-width: 285px

                +touch
                    width: 100%

            &_1::before
                background-color: #0B8087
                background-image: $mod1Img

                +contrast
                    background-color: #0B8087

            &_2::before
                background-color: #00598A
                background-image: $mod2Img

                +contrast
                    background-color: #00598A

            &_3::before
                background-color: #161E66
                background-image: $mod3Img

                +contrast
                    background-color: #161E66

    &__hr
        max-width: 135px
        margin: 10px 0
        border: 0
        border-top: 1px solid #C0C0C0

    &__more
        display: flex
        align-self: end

    &__text
        &--header
            @extend .text__heading--home
            text-align: center

            +touch
                margin-bottom: 25px

        &--title
            @extend .text--heading-sub
            font-weight: 500
            margin: 0
            text-transform: capitalize

        &--content
            @extend .text--body
            @extend .text--color-dark

        &--more
            @extend .text__link

    &__description
        max-width: 850px
        text-align: center
        margin: 0 auto
        padding: 0 20px

    &__carousel
        max-width: 350px

    &__slide
        max-width: 350px
        width: 100%
        padding: 35px 45px
        margin: 40px 0
        position: relative
        z-index: 1
        box-sizing: border-box
        box-shadow: 0 0 10px 0 rgba(66, 68, 90, 0.3)

        +desktop
            background: rgba(255, 255, 255, 0.95)

        &_1
            background: #0B8087 $mod1Img no-repeat
            background-size: contain

        &_2
            background: #405477 $mod2Img no-repeat
            background-size: cover

        &_3
            background-size: contain
            background: #8E4885 $mod3Img no-repeat right

.modules__slider
    :global(.slick-slide) > div
        +mobile
            margin: 0 35px

    :global(.slick-list)
        +mobile
            margin: 0 -35px
