@mixin define-alignment($position)
    p:global(.has-text-align-#{$position})
        text-align: $position

.container        
    @include define-alignment(center)
    @include define-alignment(left)
    @include define-alignment(right)

    p
        white-space: normal !important
        line-height: 1.7

    ul, ol, li, b, strong, em, code, kbd, h1, h2, h3, h4, h5, h6
        all: revert

    h1, h2, h3, h4, h5, h6
        font-weight: normal

    h1
        font-size: 6rem
        line-height: 1.1
    
    h2
        font-size: 3rem
        line-height: 1.3

    h3
        font-size: 2rem
        line-height: 1.3

    h4
        font-size: 1.5rem 
        line-height: 1.3
        font-weight: 600

    h5
        font-size: 1.125rem
        line-height: 1.3
        font-weight: 600

    h6
        font-size: 1rem
        line-height: 1.3
        font-weight: 600

    ul, ol
        padding-left: 50px

    code, kbd
        font-family: monospace

    sup, sub 
        font-size: smaller

    blockquote
        p 
            font-size: 1.25rem
            font-weight: 700
            line-height: 1.7

            &::before
                content: "“"
                padding-right: 5px

    figure
        display: flex
        flex-direction: column
        width: fit-content
        margin-left: 0

        figcaption
            margin: 10px auto

    div:global(.wp-block-file)
        display: flex
        align-items: center
        gap: 15px

        a:global(.wp-block-file__button)
            padding: 5px 10px
            background-color: #4a4a4a
            color: #FFFFFF
