@import '@/styles/_contrast.sass'

.help-icon
    width: 20px
    height: 20px
    margin: 5px
    display: inline-block

    img
        height: 100%
        width: 100%

        +contrast
            filter: invert(0.7) contrast(1)
