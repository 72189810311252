@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'

.breadcrumbs 
	margin-top: 28px

	&__list 
		display: flex
		flex-wrap: wrap
	

	&__item 
		@extend .text--body
		white-space: nowrap
		font-size: 0.9375rem

		&:not(:last-child) 
			&::after 
				content: '/'
				margin: 0 7px
				color: #B1B5B7

		a, span
			font-size: inherit
			
		
	
