@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
    
.results-info
    +widescreen
        display: flex
        justify-content: space-between
        align-items: baseline

    &__buttons
        margin-top: 55px

        +widescreen
            display: flex
            margin-top: 0

        +mobile
            display: flex
            flex-direction: column
            align-items: flex-end

        +tablet
            display: flex
            justify-content: flex-end

        &__help
            margin-top: 0 !important

            +mobile
                margin-top: 12px !important

    &__text 
        &--title
            @extend .text--heading-main

            strong
                font-weight: 700
                font-size: inherit
