@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.collectionsList
    &__search
        padding-top: 125px !important

    &__content
        display: flex
        flex-direction: row
        gap: 20px
        flex-wrap: wrap
        background-color: #F5F5F5

        +mobile
            flex-direction: column

        +contrast
            background-color: $contrast-black

        +widescreen
            display: grid
            grid-template-columns: minmax(0, 800px) minmax(0, 800px)

    &__item
        padding: 25px
        display: flex
        max-width: 800px
        gap: 32px
        background-color: #F9F9F9

        +contrast
            background-color: $contrast-black

        +mobile
            flex-direction: column

        +tablet-only
            width: 100%
            max-width: none

        +desktop-only
            width: 100%
            max-width: none

        &:hover, &:focus 
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2)

        &--desc
            display: flex
            flex-flow: column
            justify-content: space-between
            width: 480px

            strong
                font-weight: 600

            +touch
                width: 100%
                gap: 15px

            +desktop-only
                width: 100%

        &--image
            width: 255px
            height: 208px
            background-color: #F5F5F5
            background-repeat: no-repeat
            background-size: cover
            background-position: center

            +mobile
                width: 100%
                height: 208px

        &--content
            line-height: 2rem

        &--footer
            display: flex
            justify-content: end

    &__container
        padding-bottom: 50px

    &__head
        margin-top: 50px

        +desktop
            margin-top: 25px

        +touch
            margin-bottom: 55px

    &__content
        margin-top: 30px

    &__pagination
        text-align: right

    &__text
        &--title
            @extend .text--heading-main
            margin-top: 50px
            display: flex
            align-items: center
            gap: 25px

            span
                font-size: 1.75rem

            &--bold
                font-weight: 600

            p
                font-size: 1rem
                line-height: 1rem

                +mobile
                    line-height: 25px

            img
                filter: none

                +contrast
                    filter: contrast(100) invert(100%)

        &--head
            font-size: 1.25rem
            margin-top: 5px
            margin-bottom: 25px
            color: #005CA9
            display: block

        &--counter
            float: right
            margin-top: 4px
            color: #4A4A4A
            font-size: 0.9375rem

            +contrast
                color: $contrast-white

        &--date
            line-height: 2rem
            overflow: hidden
            text-overflow: ellipsis

            span
                margin-right: 1.5rem       
                white-space: nowrap     

            +touch
                display: flex
                flex-direction: column

        &--author
            +desktop
                border-left: 1px solid #ccc
                padding-left: 25px

                +contrast
                    border-color: $contrast-yellow
