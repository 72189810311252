@import '@/styles/_mixins.sass'
@import '@/styles/_breakpoints.sass'

.date
    margin: 0

    &__shadow
        opacity: 0.5
        pointer-events: none

    &__container
        display: flex
        color: #4B4B4B
        font-size: 0.9rem
        margin: 7px 0

        label
            h5
                display: none

    &__form-control
        background-color: transparent
        border-radius: 0
        border-color: transparent
        border-bottom: solid 1px #A5A5A5
        color: #4B4B4B
        font-size: 0.85rem
        padding: 3px 0
        line-height: 1
        width: 100%
        height: calc(1em + 0.65rem + 2px)

        &:hover
            border-bottom: solid 1px #22737B

        &:active
            border-bottom: solid 1px #4B4B4B

        &:focus
            border-color: transparent
            border-bottom-color: #707070

        +placeholder
            color: #818A91
            font-style: italic

    &__error
        color: #22737B
        font-size: 0.7rem
        line-height: 1rem
        display: inline-block
        position: absolute
        bottom: -25px

    &__picker
        display: grid
        margin-right: 15px
        grid-gap: 5px
        position: relative

        :global(.MuiInputAdornment-root)
            height: 100%
            max-height: unset

    &__input--container
        grid-template-columns: 125px 40px
        display: grid

    &__input
        color: #005CA9
        font-size: 1rem
        border: none
        background-color: transparent
    &__operator
        margin-top: 12px

.input
    flex: 0 1 120px

    &__text-field
        width: 150px
        height: 35px
        min-width: 125px
        min-height: 35px

.select
    flex: 0 0 80px
    padding-left: 10px

.checkbox
    color: #22737B
    align-items: center
    line-height: 18px
    display: flex
    width: 100%

    &__label
        display: flex
        flex: 1
        cursor: pointer

        &:hover
            .checkbox__label--text
                text-decoration: underline

        &--text
            flex: 1

        &--count
            color: #91999F
