@import '@/styles/_breakpoints.sass'
@import '@/styles/_contrast.sass'

.pagination
    &__pages
        display: inline-block

    &__button
        border: 0
        clip: rect(0 0 0 0)
        height: 1px
        margin: -1px
        overflow: hidden
        padding: 0
        position: absolute
        width: 1px

    &__form
        display: inline-block

    &__input
        background: transparent
        border: 0
        border-bottom: solid 2px #919191
        width: 100%
        text-align: right
        padding: 0 5px
        line-height: 1rem
        color: #161615

    &__input-label
        border: 0
        clip: rect(0 0 0 0)
        height: 1px
        margin: -1px
        overflow: hidden
        padding: 0
        position: absolute
        width: 1px

    &__total
        display: inline-block

        +mobile
            display: none

    &__total-label
        color: #161615
        font-size: 1rem
        margin: 0 3px 0 8px

    &__list
        margin: 0
        padding: 0
        display: inline-block

    &__list-item
        display: inline-block

    &__item-button
        cursor: pointer
        color: #005CA9
        border: solid 1px transparent
        background: transparent
        line-height: calc(1rem + 1px)
        padding: 4px 5px

        &:hover,
        &:focus
            text-decoration: underline

        &--disabled
            color: #161615
            pointer-events: none

            &:hover,
            &:focus
                text-decoration: none

        &--active
            color: #161615
            pointer-events: none

            &:hover,
            &:focus
                text-decoration: none

    &__item-separator
        color: #161615
        margin: 0 5px

    &__next,
    &__previous
        color: #005CA9
        border: solid 1px transparent
        background: transparent
        line-height: calc(1rem + 1px)
        padding: 4px 5px
        position: relative
        display: inline-block

        &:hover,
        &:focus
            text-decoration: underline
            cursor: pointer

    &__next
        margin-left: 10px
        padding-right: 12px

        +mobile-small
            margin: 0
            padding: 10px

    &__previous
        margin-right: 10px
        padding-left: 12px

        +mobile-small
            margin: 0
            padding: 10px

\:global(.contrast)
    .pagination
        &__item
            &-button
                color: $contrast-yellow

                &--active
                    color: $contrast-white

            &-separator
                color: $contrast-white

        &__total-label
            color: $contrast-white

        &__next,
        &__previous
            color: $contrast-yellow

            &::after
                border-left-color: $contrast-yellow

            &::before
                border-right-color: $contrast-yellow

        &__input
            color: $contrast-yellow

            &-label
                color: $contrast-yellow

\:global(.font-big)
    .pagination
        &__next
            +desktop
                margin-left: 5px
                padding-right: 10px

        &__previous
            +desktop
                margin-right: 5px
                padding-left: 10px
