@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.cookies 
    position: fixed
    left: 0
    bottom: 0
    background: rgba(255,255,255,0.95)
    width: 100%
    padding: 15px 0
    -webkit-box-shadow: 0px 0px 24px 0px rgba(170, 170, 170, 1)
    -moz-box-shadow: 0px 0px 24px 0px rgba(170, 170, 170, 1)
    box-shadow: 0px 0px 24px 0px rgba(170, 170, 170, 1)
    z-index: 999999
    +contrast
        background: black

    &__container
        display: grid
        grid-gap: 15px

        +desktop
            display: flex
            grid-gap: 5px
            justify-content: space-between
            align-items: center

        +mobile
            line-height: 1.25
            display: flex
            flex-direction: column
            align-items: flex-end

