@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.search
    background: #005CA9
    width: 100%
    position: relative
    height: 565px
    display: flex
    justify-content: center
    align-items: center

    +contrast
        background-color: $contrast-black

    +touch
        height: 350px

    +widescreen
        height: 405px

    +hd
        height: 565px

    &--main
        height: auto
        display: flex
        flex-direction: column
        align-items: center
        padding: 27px 0

    &--button
        width: calc(100% - 160px)
        height: 81px

    &__popup
        width: calc(100vw - 54px)
        max-width: 1760px

        +mobile
            width: calc(100% - 74px)

        +mobile
            top: 0
            align-items: start

    &__close
        display: none
        
        +mobile
            display: flex
            flex-direction: row-reverse
            align-items: center
            height: 70px
            background-color: #004E90
            color: #FFF
            padding-right: 20px

        &__text
            margin-right: 3px

        i
            font-size: 20px
            margin-bottom: -1px


    &__close-btn
        position: absolute
        top: 50px
        right: 40px
        display: flex
        line-height: 1.5rem
        gap: 5px

        +mobile
            top: 140px

    &__container
        +mobile
            padding: 0
            margin-top: 6px
            max-width: none

        +widescreen
            max-width: 800px

        +hd
            max-width: 1055px

    &__row
        +mobile
            margin-left: 0
            margin-right: 0

    &__col
        +mobile
            padding: 0

    &__header
        margin-bottom: 40px

        +mobile
            display: none

    &__form
        width: 100%

        &-tabs
            +mobile
                display: grid
                grid-template-columns: 1fr 1fr
                margin-top: 36px

    &__select
        width: 290px
        color: #005CA9

        +mobile
            width: 100%

    &__button
        &--remove
            width: 143px

            +mobile
                width: auto

        &--higher
            //height: 77px

        &--add
            //height: 73px

        &--clear
            //height: 77px

    &__searcharea
        width: 100%
        background-color: #FFFFFF
        padding: 19px

        :global(.MuiFormControl-root)
            label
                display: none
            label + div
                margin-top: 0

        +contrast
            background-color: $contrast-yellow

        +mobile
            padding: 14px
            padding-bottom: 1px

        &--wrapper
            display: flex
            justify-content: space-between

        &__themes
            display: inline-flex
            flex-wrap: wrap
            gap: 5px

            &--item
                width: fit-content

                +mobile
                    width: 100%

        

            

    &__actions-area
        padding-top: 1px
        padding-bottom: 0

    &__actions-area button
        +mobile
            width: 100%

    &__adv-searcharea
        background-color: #FFFFFF
        padding-top: 18px
        padding-bottom: 18px
        padding-left: 14px
        padding-right: 14px
        width: 100%

        +contrast
            background-color: $contrast-yellow

        +mobile
            width: auto

        &--wrapper
            margin-top: 12px

        label 
            display: none

        label + div
            margin-top: 0

    &__footer
        padding-top: 43px

        +mobile
            padding-top: 0

    &__footer button
        +mobile
            width: 100%

    &__form-wrapper
        display: flex
        flex-wrap: wrap
        flex-direction: column
        gap: 8px
        justify-content: flex-start

        &.header
            position: relative
            margin-top: 10px
            @media(max-width: 1199px)
                top: 15px
            @media(max-width: 1024px)
                display: flex

            .search
                &__advsearch__link
                    font-size: 0.8rem
                    line-height: 1.5rem

            &:first-of-type
                @media (min-width: 1024px)
                    margin-top: 0

    &__inputrow
        @media(max-width: 1024px)
            position: absolute
            top: 0
            left: 0
            width: 100%
            margin: 0

        +desktop
            margin-top: 1rem
            margin-bottom: 1rem

    &__inputgroup
        display: flex
        flex-direction: column
        position: static

        +desktop-only
            position: relative
            top: -16px

    &__dropdowntoggle
        height: 100%

    &__form
        .search__button
            z-index: 0
            background: #326B82
            color: #FFFFFF
            border-color: #71A6AE
            border-radius: 0

            &:disabled
                background-color: #ECEEEF
                border-color: #DDDDDD
                color: #828A91

            &:hover
                background: #245366
                color: #FFFFFF
                border-color: #52858E

            &--mobile
                +tablet
                    display: none

                

            &--desktop
                +mobile
                    display: none

        &.header
            padding: 0 20px
            @media screen and (max-width: 1024px)
                padding: 0

        &:focus-within
            .search__button
                background: #236B83
                color: #FFF
                border-color: #64A3AC

    &__button
        +touch
            border-radius: 0

        +desktop
            width: 78px

        +widescreen
            width: 68px

        +hd
            width: 78px

        &:focus
            border: 1px solid #DFE1E1
            box-shadow: none

        &:hover
            background: #245366
            color: #FFFFFF
            border-color: #52858E

        &:disabled
            pointer-events: none
            opacity: 1

        &.header
            width: 50px

        span
            display: none

    &__logo
        display: flex
        justify-content: center
        align-items: center
        position: relative
        bottom: -5px
        z-index: 0
        height: 60px

        &::before
            content: ''
            display: block
            border-radius: 30px
            width: 180px
            height: 60px
            background: rgba(#1D1B19, 0.7)
            position: absolute
            bottom: 0
            left: 0
            right: 0
            margin: 0 auto
            z-index: 1

        &::after
            content: ''
            display: block
            width: 190px
            height: 70px
            border-radius: 35px
            position: absolute
            z-index: 0

        img
            width: 116px
            z-index: 2
            filter: brightness(0) invert(1)

    &__motto
        background: rgba(#1D1B19, 0.7)
        color: #FFFFFF
        font-size: 1.25rem
        padding: 17px 15px 16px

        +touch
            font-size: 0.95rem
            padding: 21px 15px 13px
            line-height: 1.3

            span
                max-width: 320px

        +widescreen
            font-size: 1rem
            padding: 12px 15px 13px

        +hd
            font-size: 1.25rem
            padding: 17px 15px 16px

    &__input
        width: 100%
        height: 100%
        flex: 1
        height: 54px

        +mobile
            height: 45px

        +widescreen
            height: 46px

        +hd
            height: 58px

        &.header
            height: 40px

        &__mobile
            width: 100%

            & > div 
                +touch
                    +contrast
                        background: $contrast-yellow

            label 
                display: none

            label + div
                margin-top: 0

    &__advsearch
        &__link
            border: 0
            font-size: 0.9rem
            outline: 0
            color: #71CDD5
            background: rgba(28, 28, 28, 0.52)
            float: right
            white-space: nowrap
            padding: 0 5px

            &:hover
                color: #71CDD5

                span
                    text-decoration: underline

            &:focus
                color: #71CDD5

                span
                    text-decoration: underline

            &.header
                background-color: transparent

                span
                    color: #22737B

                i
                    color: #22737B

                &:active
                    color: #4B4B4B

                    span
                        color: #4B4B4B

                    i
                        color: #4B4B4B

            &:active
                color: #FFFFFF

            +hd
                font-size: 1rem

            i
                position: relative
                top: -3px
        &-date-container
            display: grid
            grid-gap: 20px
            grid-template-columns: 1fr
            +widescreen
                grid-template-columns: 1fr 1fr
        &-date-error
            font-size: 0.8rem
            color: red

    &__europeana
        color: #FFFFFF
        background: rgba(28, 28, 28, 0.52)
        display: flex
        align-items: center

        &.header
            color: #212529
            background: transparent
            line-height: 1.5rem
            padding-left: 0

            .search
                &__checkbox
                    margin-right: 0
                    top: -5px

            label
                font-size: 0.8rem

        label
            margin-bottom: 0
            padding: 0 5px
            font-size: 0.9rem
            white-space: nowrap
            cursor: pointer

            &:hover, &:focus
                text-decoration: underline

            +hd
                font-size: 1rem
                // margin-left: 5px

        span
            margin-left: 5px

        i
            color: #71CDD5
            position: relative
            top: -3px

        svg
            transform: scale(0.65)

            +hd
                transform: scale(0.85)

        &__label
            &--span
                display: none

\:global(.contrast)
    .search
        &__motto
            background: #1D1B19

        &__logo
            &::before
                background: #1D1B19

            &::after
                background: rgba(29, 27, 25, 0.7)

        &__advsearch
            &__link
                &,
                &.header span,
                &.header i
                    color: #FFF500
                    background: #1C1C1C

                    &:active
                        color: #FFF

        &__europeana
            i
                color: #FFF500

            &.header
                span
                    color: #FFF

        &__button
            background: #1C1C1C
            border: 2px solid #FFF500
            color: #FFF

        &__form
            .search__button
                &:disabled
                    background: #1C1C1C
                    border: 2px solid #FFF500
                    color: #FFF

            &:focus-within
                .search__form-wrapper
                    outline: 0

                .search__input > input
                    border: 1px solid #FFF500

                .search__button
                    &,
                    &:hover,
                    &:focus
                        background: #FFF500
                        color: #1C1C1C
                        border-color: #FFF500

\:global(.font-medium)
    .search
        &__input
            height: 43px

\:global(.font-big)
    .search
        &__button
            @media (max-width: 1024px)
                height: 40px
                padding: 0
