
@import './_normalize.scss'
@import './_reset.scss'
@import './_mixins.sass'
// @import "./_custom-bootstrap.sass"
@import './_icons.scss'
@import './_icons-ext.sass'
// @import "./_checkbox.sass"
// @import "./_tooltip.sass"
// @import "./_modal.sass"
@import './_contrast-global.sass'
//@import "./_contrast-mui.sass"
// @import "./_font-size.sass"
// @import "./index.sass"
//@import './dropdown.sass'


@import '@/styles/_text.sass'
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&family=Roboto:wght@400;700;900&display=swap')

html, #root
  height: 100%
  font-size: 16px

  &.big
    font-size: 18px

  &.bigger
    font-size: 20px

body
  @extend .text--primary-font
  margin: 0
  background: #F5F5F5
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.container
  max-width: 1760px
  padding: 0 20px
  margin: 0 auto

.first-block
  padding-top: 125px

a
  color: #005CA9
  text-decoration: none

  &:hover, &:focus
    text-decoration: underline

  &.text--white
    color: #FFF

  &[target='_blank']:not([data-noicon])
    &::after
      @extend .icons
      content: "\e806"
      font-size: 0.875em
      margin-left: 5px
      position: relative
      top: -0.1em
  

.leaflet-container
  height: 400px

html.mourn *
  filter: grayscale(1) !important
  filter: grayscale(1) !important


a.read-more-link 
  display: none