@import '@/styles/_breakpoints.sass'

.results
    &__container
        // padding-top: calc(125px + 130px)

    &__blocks
        display: grid
        grid-gap: 70px
        grid-template-columns: minmax(100px, 1fr)

        +desktop
            grid-gap: 20px
            margin-top: 70px

        +widescreen
            grid-template-columns: 420px auto

    &__info
        margin-top: 50px
