@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'

.search-form
    &__submit
        // border: 0
        // clip: rect(0 0 0 0)
        // clip-path: polygon(0px 0px, 0px 0px, 0px 0px)
        // -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px)
        // height: 1px
        // margin: -1px
        // overflow: hidden
        // padding: 0
        // position: absolute
        // width: 1px
        // white-space: nowrap

    &__input
        border: 0
        border-bottom: solid 1px #A5A5A5
        box-shadow: none
        border-radius: 0
        font-size: 0.83rem
        line-height: 1rem
        color: #4B4B4B
        padding: 0

        +placeholder
            color: #818A91
            font-style: italic

        :focus
            border-color: #64A3AC

            + .search-form__icon
                border-color: #64A3AC

    &__field
        padding: 10px 10px 0
        display: flex

    &__icon
        border-bottom: solid 1px #A5A5A5
        padding: 5px
        line-height: 1rem
        color: #22737B

\:global(.contrast)
    .search-form
        &__input
            background-color: #0C0B0B
            border-color: #FFF
            color: #FFF

            +placeholder
                color: #FFF

            :focus
                border-color: #FFF500

                + .search-form__icon
                    border-color: #FFF500

        &__icon
            color: #FFF500
            background-color: #0C0B0B
            border-color: #FFF
