@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import 'styles/_contrast.sass'
@import 'wordpress'

.staticPage
    &__search
        padding-top: 125px !important

    &__container
        padding-bottom: 50px

    &__head
        display: flex
        align-items: center
        place-content: space-between
        margin-top: 50px

        +desktop
            margin-top: 25px

        +mobile
            flex-direction: column
            align-items: flex-start

    &__content
        margin-top: 30px
        @include word-wrap

    &__text
        &--title
            @extend .text--heading-main

        &--date
            color: #4A4A4A

            strong
                color: #161615

.staticPage__content
    @extend .wordpress

    ol, ul 
        padding-top: 5px
        padding-bottom: 5px

        li 
            margin-top: 5px
            margin-bottom: 5px
            
    p 
        margin-bottom: 1rem
    

    &--sources
        display: flex
        flex-wrap: wrap
        gap: 30px 200px
        align-items: center
        justify-content: center
        background-color: #FFFFFF
        padding: 50px 100px

        @media (max-width: 900px)
            gap: 30px 150px

        @media (max-width: 600px)
            gap: 50px 50px

.staticPage__contact
    display: grid
    grid-gap: 25px
    margin-top: 45px
    +desktop
        grid-template-columns: 5fr 4fr
        grid-gap: 50px
    +widescreen
        grid-gap: 100px

    +fullhd
        grid-gap: 165px    

    &--right
        background: white
        padding: 15px


\:global(.contrast)
    .staticPage
        &__text
            &--date
                color: $contrast-white

                strong
                    color: $contrast-yellow
        &__contact
            &--right
                background: transparent