@import "@/styles/_breakpoints.sass";
@import "@/styles/_contrast.sass";

.map {
	@include contrast {
		color: $contrast-white;
	}
}

.tree {
	margin-top: 50px;
	margin-left: 150px;
	margin-bottom: 50px;
	--spacing: 1rem;
	--radius: 10px;
	& li {
		display: block;
		position: relative;
		padding-left: calc(2 * var(--spacing) - var(--radius));
	}

	& ul {
		margin-left: calc(var(--radius) - var(--spacing));
		padding-left: 0;
	}

	& ul li {
		border-left: 2px solid #ddd;
		margin: 0.5rem;
	}

	& ul li:last-child {
		border-color: transparent;
	}

	& ul li::before {
		content: "";
		display: block;
		position: absolute;
		top: calc((-0.5) * var(--spacing) - 5px);
		left: -2px;
		width: calc(var(--spacing) + 2px);
		height: calc(var(--spacing) + 1px);
		border: solid #ddd;
		border-width: 0 0 2px 2px;
	}

	& ul li:first-child::before {
		border-width: 0 0 2px 2px;
	}

	& li::after {
		content: "";
		display: block;
		position: absolute;
		top: calc((var(--spacing) / 2 - var(--radius)) - 2px);
		left: calc(var(--spacing) - var(--radius) + 10px);
		width: 0.5rem; //calc(1 * var(--radius));
		height: 0.75rem; //calc(2 * var(--radius))
		border: none;
		background: #f5f5f5;
		@include contrast {
			background-color: $contrast-black;
		}
	}

	& ul::before {
		margin-left: 0.75rem;
		content: "";
		background: transparent;
	}
}
