@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.publications
    &__container
        padding-top: 100px
        padding-bottom: 45px

        +desktop-only
            padding-bottom: 0

    &__blocks
        +fullhd
            display: grid
            grid-gap: 50px
            grid-template-columns: repeat(2, 1fr)

        +touch
            display: block

        

    &__block
        position: relative

        +desktop
            &:not(:first-child)
                &::before
                    content: ''
                    background: #CCC
                    width: 1px
                    height: 100%
                    position: absolute
                    left: -25px
                    top: 0
                    display: block

        +desktop-only
            margin-bottom: 60px

    &__list
        display: grid
        align-items: flex-start
        grid-template-columns: repeat(3, 1fr)
        grid-gap: 50px
        margin-top: 30px

        +desktop
            margin-top: 100px

        +desktop-only
            display: flex
            justify-content: space-between
            margin-top: 10px

        +widescreen-only
            display: flex
            justify-content: space-between
            margin-top: 10px

        +hd-only
            display: flex
            justify-content: space-around
            margin-top: 10px

    &__item
        display: flex
        flex-direction: column-reverse
        width: 255px

        &--tile
            height: 295px

            img
                width: 100%
                height: 100%
                object-fit: contain

        &--icon
            opacity: 1
            pointer-events: auto
            transition: all 0.3s ease-in-out
            width: 70px
            height: 70px
            background: #FFFFFF
            display: flex
            justify-content: center
            align-items: center
            left: 0
            bottom: 0
            position: absolute

        &--active
            opacity: 0
            pointer-events: none
            transition: all 0.3s ease-in-out
            position: absolute
            width: 100%
            height: 100%
            left: 0
            top: 0
            background: rgba(#005CA9, 0.5)
            display: flex
            flex-direction: column
            padding: 35px
            box-sizing: border-box
            color: #FFFFFF

        &--info,&--content
            margin-top: 20px

        &--content
            flex: 1

        &--bottom
            +mobile
                padding-right: 15px

        &:hover, &:focus
            cursor: pointer

            .collections__item
                &--tile
                    box-shadow: 0 0 10px 0 rgba(66, 68, 90, 0.3)

                &--icon
                    opacity: 0
                    pointer-events: none

                &--active
                    opacity: 1
                    pointer-events: auto

    &__header
        min-height: 80px

        &--bottom
            margin-top: 35px

            &__title
                display: -webkit-box
                -webkit-line-clamp: 2
                -webkit-box-orient: vertical
                overflow: hidden
                letter-spacing: 1px
                margin-bottom: 15px
                height: 3.125em

            &__info
                display: grid
                grid-template-rows: 1fr 1fr 1fr
                align-items: start

        &--second
            +touch
                margin-top: 80px
                margin-bottom: 0
                min-height: auto

    &__footer
        display: flex
        justify-content: center
        margin-top: 45px

    &__text
        &--header
            @extend .text__heading--home
            color: #4A4A4A

            +contrast
                color: #ffffff

            +touch
                font-size: 25px                 

        &--title
            @extend .text--color-blue
            margin-bottom: 15px
            font-weight: 500

            a
                font-size: 1.25rem !important

        &--content
            @extend .text--body
            @extend .text--color-dark

        &--collection-name
            @extend .text--heading-sub
            font-weight: 800
            text-decoration: underline

        &--collection-val
            @extend .text--body
            font-weight: 600

        &--collection-content
            @extend .text--body

        &--key
            font-size: 1rem
            font-weight: normal
            white-space: nowrap
            color: #4A4A4A

            +contrast
                color: #ffffff

        &--value
            font-size: 1rem
            font-weight: 500
            white-space: nowrap
            line-height: 26px
            color: #161615

            +contrast
                color: #ffffff

        &--info
            overflow: hidden
            text-overflow: ellipsis

