@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'

.modal
    &__title
        font-size: 1.25rem
        margin-bottom: 40px

    &__form
        display: flex
        flex-direction: column
        gap: 40px

        &--group
            display: flex
            flex-direction: column
            gap: 13px

            p 
                margin-top: 4px
                margin-bottom: 4px
                font-size: 0.875rem

    &__select
        border-bottom: 1px solid #444444


    &__btn
        &--close
            float: right


    &__addnew            
        margin-top: 30px

    &__footer           
        display: flex
        justify-content: right
        gap: 13px

        +mobile
            flex-direction: column

    &__input
        &--short
            width: 60%
            
    &__error
        color: #FF0000
