@import './_contrast.sass'

.contrast
  background-color: $contrast-black
  color: $contrast-white

  a:not([class^='Mui'])
    color: $contrast-yellow

  h1,h2,h3,h4,h5,h6
    color: $contrast-white

  .dropdown
    &-toggle, &-item
      &:focus
        outline: none

  .container
    //background-color: $contrast-black
    color: $contrast-white
