@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.footer
    &__upper
        background: #E2E2E2
        padding: 85px 0 60px

        +contrast
            background-color: $contrast-black

    &__lower
        padding: 45px 0
        text-align: center

        +contrast
            background-color: $contrast-black

    &__logo
        margin-bottom: 45px

        &--img
            max-width: 185px

    &__menu
        display: grid
        align-items: flex-start
        grid-gap: 50px

        +tablet
            grid-template-columns: repeat(2, 1fr)

        +desktop
            grid-template-columns: repeat(4, 1fr)

        +widescreen
            grid-gap: 100px
            
        +fullhd
            grid-gap: 200px

        &--col
            display: grid
            grid-gap: 50px
            align-items: flex-start

            &-info
                grid-gap: 30px

        &--box
            display: grid
            grid-gap: 12px

        &--list
            display: grid
            grid-gap: 12px

    &__text
        &--title
            @extend .text--body
            font-weight: 800

        &--subtitle
            @extend .text--body

        &--menu
            @extend .text--body
            

        &--copyright
            @extend .text--body
