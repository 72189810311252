// @use '@/assets/styles/utils/' as utl;

@import "@/styles/_contrast.sass";

.loader {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: top;
	padding: 450px 0 300px;
	box-sizing: border-box;

	&__circle {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;

		div {
			box-sizing: border-box;
			display: block;
			position: absolute;
			width: 64px;
			height: 64px;
			margin: 8px;
			border: 8px solid #005ca9;
			border-radius: 50%;
			animation: loading-circle 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-color: #005ca9 transparent transparent transparent;

			&:nth-child(1) {
				animation-delay: -0.45s;
			}

			&:nth-child(2) {
				animation-delay: -0.3s;
			}

			&:nth-child(3) {
				animation-delay: -0.15s;
			}
		}
	}
}

@keyframes loading-circle {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

:global(.contrast) {
	.loader {
		&__circle {
			div {
				border-color: $contrast-white transparent transparent transparent;
			}
		}
	}
}
