@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.collectionsList
    &__head
        margin-top: 50px
        margin-bottom: 40px
        
        +desktop
            margin-top: 25px

    &__text
        &--title
            margin-top: 50px
            display: flex
            align-items: center
            gap: 25px
            @extend .text--heading-main

            +mobile
                line-height: 2rem

            & span
                font-size: 1.75rem
            & p
                font-size: 1rem
                line-height: 1rem
            & img
                filter: none
                +contrast
                    filter: contrast(100) invert(100%)

            &--bold
                color: #161615
                font-size: 2rem
                font-weight: bold

            &--main
                margin-top: 50px

.collectionsItem
    &__container
        padding-bottom: 50px

    &__head
        margin-top: 50px

        +desktop
            margin-top: 25px

        &--buttons
            width: 100%
            display: flex
            justify-content: flex-end
            gap: 20px

            +mobile
                flex-direction: column
                margin-top: 25px

            +contrast
                > img
                    filter: contrast(100) invert(100)

    &__content
        margin-top: 30px

    &__text
        &--title
            @extend .text--heading-main

    &--desc
        margin: 50px 0
        background-color: #F9F9F9
        padding: 1.75rem 3.25rem 1.75rem 3.25rem
        color: #4A4A4A

        strong
            color: #161615

    &--head
        font-size: 1.25rem
        margin-top: 5px
        margin-bottom: 32px
        letter-spacing: 1px
        font-weight: 500
        color: #005CA9

    &--counter
        float: right
        margin-top: 4px
        color: #4A4A4A
        font-size: 0.9375rem

        +contrast
            color: $contrast-white

        +touch
            display: none

    &--header
        strong
            font-weight: 600

    &--date
        line-height: 2rem

    &--content
        margin-top: 30px

        &--link
            @include word-wrap

    &__alert
        display: flex
        align-items: center
        padding: 0 20px
        gap: 20px
        border: 1px solid #005CA9
