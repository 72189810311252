@import '@/styles/_breakpoints.sass'
@import '@/styles/_contrast.sass'

.dictionary
    box-sizing: border-box

    &__entry
        margin-top: 17px
        max-width: 1000px

        &--word
            color: #161615
            font-size: 18px
            font-weight: 500

            +contrast 
                color: white

        &--description
            width: 100%

            +desktop
                width: 60%

    &__letter
        background-color: #F9F9F9
        width: auto
        padding: 17px
        margin-bottom: 17px
        overflow-wrap: break-word

        +contrast
            background: #000

        &__block
            display: flex
            justify-content: center
            align-items: center
            width: 55px
            height: 55px
            color: #FFFFFF
            background-color: #4A4A4A

            +contrast
                background: $contrast-yellow
                
                h3
                    color: #000
            
        &__header
            font-size: 16px !important

        &__line
            border-bottom: 1px solid #E2E2E2
            margin: 0 80px 0 100px

            +touch
                margin: 0 30px 0 80px
                width: auto
