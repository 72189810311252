.datepicker
    &__input
        color: #005CA9
        font-size: 1.25rem
        border: none

        &--container
            display: grid
            grid-gap: 20px
            grid-template-columns: 185px 75px
            :global(.MuiInputAdornment-root)
                height: 100%
                max-height: unset

    &__data-input
        grid-template-columns: 145px 40px
        display: grid
