@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
    
.news
    &__container 
        padding-top: 45px
        padding-bottom: 45px

        +desktop
            padding-top: 100px

    &__blocks
        display: grid
        grid-gap: 80px
        margin-top: 50px

        +desktop
            grid-template-columns: repeat(2, 1fr)

    &__block 
        position: relative

        &:not(:first-child)
            &::before
                content: ''
                background: #CCC
                width: 1px
                height: 100%
                position: absolute
                left: -25px
                top: 0
                display: block

    &__list
        display: grid
        grid-gap: 30px

        +touch
            &::before, &::after
                content: ''
                background: #CCC
                width: 100%
                height: 1px
                position: absolute
                left: 0
                display: block

            &::before
                top: -35px

            &::after
                bottom: -35px

    &__item
        display: flex
        flex-direction: column

        &--full
            .news__item--header
                display: flex
                align-items: baseline
                gap: 10px
                justify-content: space-between
                margin-bottom: 50px

                +mobile
                    flex-direction: column
                    margin-bottom: 1rem

                strong
                    font-weight: 500

                p
                    flex-shrink: 0

            .news__item--content
                margin-top: 40px

        &--short
            .news__item--header
                display: flex
                align-items: baseline
                gap: 10px
                justify-content: space-between
                margin-bottom: 30px

                +mobile
                    flex-direction: column

                strong
                    font-weight: 500

                p
                    flex-shrink: 0

        &--pic
            height: 300px
            background: #CCC
            background-position: center
            background-size: cover

        &--content
            p
                @extend .text--body

        &--footer 
            margin-top: 30px
            
    &__empty
        display: flex
        justify-content: center
        margin-top: 30px

    &__footer
        display: flex
        justify-content: center
        margin-top: 60px

        +touch
            margin-top: 70px


    &__text
        &--header
            @extend .text__heading--home
            text-align: center

        &--title
            @extend .text--heading-sub
            @extend .text--color-blue

        &--date
            @extend .text--body
            @extend .text--color-anthracit

        &--collection-name
            @extend .text--heading-sub
            font-weight: 800
            text-decoration: underline

        &--collection-val
            @extend .text--body
            font-weight: 600

        &--collection-content
            @extend .text--body
