@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'

.ipp
    display: flex
    align-items: baseline

    label
        h5
            display: none

    &__title
        margin-right: 5px

    &__text
        &--title
            @extend .text--title
