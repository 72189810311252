@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'

.facet
    &__dropdown
        border-radius: 0

    &__toggle
        text-align: left
        width: 100%
        position: relative
        font-size: 0.8rem
        background-color: #4A4A4A
        letter-spacing: -0.1px
        border-radius: 0
        padding: 5px 0

        +fullhd
            font-size: 0.95rem

        &::after
            position: absolute
            right: 8px
            top: calc(50% - 3px)

        &:hover, &:focus
            box-shadow: 0 2px 0 0 #29828A
            z-index: 9

        &[disabled]
            pointer-events: none
            opacity: 1
            color: #999999

            &::after
                display: none

        &[aria-expanded='true']
            border-radius: 5px
            font-weight: 600
            background: #236B83
            color: #FFFFFF
            font-size: 0.9rem
            padding: 7px 11px
            letter-spacing: 0.3px
            border-top: 1px solid transparent

            &::after
                right: 10px

            &:hover, &:focus
                box-shadow: none

        &__text
            display: flex
            justify-content: space-between
            align-items: center
            width: 100%

            &__tooltip
                padding: 15px

            img
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(236deg) brightness(102%) contrast(103%)

    &__menu
        position: static !important
        transform: none !important
        float: none
        padding: 0
        margin: 3px 0
        // border-radius: 5px
        // border-color: #dadada

    &__no-facet
        padding: 8px 10px 7px
        font-size: 0.83rem
        line-height: 1rem
        color: #4B4B4B
        padding-left: 80px


\:global(.contrast)
    .facet
        &__toggle
            color: #FFF500
            border-color: #333333

            &:hover,
            &:focus
                box-shadow: 0 2px 0 0 #FFF500

            &[aria-expanded='true']
                background: #FFF500
                color: #0D0C0C

        &__no-facet
            color: #FFF
