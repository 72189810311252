@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.topicsList
    &__search
        padding-top: 125px !important

    &__info
        padding: 25px 25px 25px 0

        &--title
            font-size: 2em

        &--info
            margin-top: 20px
            max-width: 900px
            color: #4A4A4A
            line-height: 2rem
            font-weight: 500

            +touch
                margin-top: 5px

            +contrast
                color: $contrast-white

    &__content
        display: flex
        flex-direction: row
        flex-wrap: wrap
        gap: 20px
        background-color: #F5F5F5

        +contrast
            background-color: $contrast-black

    &__item
        padding: 25px
        width: 100%
        display: flex
        flex-direction: column
        background-color: #F9F9F9
        box-sizing: border-box

        +contrast
            background-color: $contrast-black

        +touch 
            flex-direction: column
            height: 100%

        &__link
            width: 100%
            display: flex
            background-color: #FFFFFF
            justify-content: flex-end
            gap: 10px

            &--link
                height: 100%
                display: inline-block
                line-height: 50px
                padding-left: 10px
                margin-right: auto
                overflow-x: hidden
                white-space: nowrap
                text-overflow: ellipsis
                color: #005CA9
                width: 100%
                font-weight: bold

                +contrast
                    color: black !important

            &--copy-link
                margin-left: auto
                flex-shrink: 0

                +touch
                    display: none !important

                &--mobile
                    display: none

                    +touch
                        display: block

        &__parameters
            margin-top: 10px
            width: 100%
            display: flex
            flex-direction: row

            +touch
                flex-direction: column

            &__info
                display: flex
                flex-direction: column
                justify-content: center

                &--param-name
                    color: #4A4A4A

                    +contrast
                        color: $contrast-white

                &--param-value
                    color: #161615
                    

                    +contrast
                        color: $contrast-white

                &--creation-date
                    padding: 5px

                &--params
                    padding: 5px

            &__actions
                margin-left: auto
                display: flex
                gap: 20px
                flex-shrink: 0
                height: 50px

                +touch
                    flex-direction: column-reverse
                    height: 100%
                    width: 100%

                &--periodicity
                    display: flex
                    align-items: center
                    height: 100%

                    +touch
                        display: none
                    
                    &--mobile
                        display: none

                        +touch
                            display: block
                            text-align: right

                &--delete-topic

                    +touch
                        align-self: flex-end

            

        &--desc
            display: flex
            flex-flow: column
            justify-content: space-between

            +touch 
                width: auto
                gap: 15px

        &--image 
            width: 255px
            height: 208px
            background-color: #F5F5F5
            background-repeat: no-repeat
            background-size: cover

        &--content
            line-height: 2rem

        &--header
            margin-bottom: 10px

        &--footer
            display: flex
            justify-content: end
            width: 800px
            gap: 30px

    &__container
        padding-bottom: 50px

    &__head
        margin-top: 50px
        margin-bottom: 30px

        +desktop
            margin-top: 25px

        &--button 
            margin-top: 25px
            float: right
            
    &__content
        margin-top: 30px
        margin-bottom: 30px

    &__pagination
        text-align: right

    &__text
        &--title
            @extend .text--heading-main
            margin-top: 50px
            display: flex
            flex-direction: row
            align-items: flex-start
            gap: 25px

            +touch
                justify-content: space-between

            span
                font-size: 1.75rem
                font-weight: normal

            p 
                font-size: 1rem
                line-height: 1rem

            div
                span:first-of-type
                    font-weight: 500
                    margin-right: 5px

            img
                +contrast
                    filter: contrast(100) invert(100%)

        &--head
            font-size: 1.25rem
            margin-top: 5px
            margin-bottom: 20px
            color: #005CA9

        &--counter      
            float: right
            margin-top: 4px
            color: #4A4A4A
            font-size: 0.9375rem

            +contrast
                color: $contrast-white

            +touch 
                display: none

        &--date 
            line-height: 2rem
