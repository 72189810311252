@import '@/styles/_contrast.sass'

.text
    &--heading-big
        font-size: 3rem
        line-height: 3.5rem

        +tablet
            font-size: 5rem
            line-height: 5.5rem

        +desktop
            font-size: 7rem
            line-height: 8.5rem

    &--heading-main
        font-size: 1.75rem
        line-height: 3.375rem

    &--heading-sub
        font-size: 1.5625rem
        line-height: 2.1875rem

    &--title
        font-size: 1.125rem
        line-height: 1.75rem

        +desktop
            font-size: 1.25rem
            line-height: 2.25rem

    &--description
        font-size: 1rem
        line-height: 1.625rem

        +desktop
            font-size: 1.125rem
            line-height: 1.75rem

    &--body
        font-size: 1rem
        line-height: 1.625rem

    &--color-blue
        color: #005CA9
        +contrast
            color: #fff

    &--color-black
        color: #000000

        +contrast
            color: #fff

    &--color-anthracit
        color: #6F6F6F

        +contrast
            color: #fff

    &--color-dark
        color: #4a4a4a

        +contrast
            color: #fff

    &--color-grey
        color: #C0C0C0

        +contrast
            color: #fff

    &--color-white
        color: #FFFFFF

    &--primary-font
        font-family: 'Readex Pro', sans-serif

    &--secondary-font
        font-family: 'Roboto', sans-serif

    &__link
        letter-spacing: 1px
        font-weight: 500

        font-size: 1.125rem
        line-height: 1.75rem

        +desktop
            font-size: 1.25rem
            line-height: 1.5rem

    &__heading--home
        @extend .text--heading-main
        font-weight: 500
        margin: 0
