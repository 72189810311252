@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_contrast.sass'
// @import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@500&display=swap');

.facets
    // box-sizing: border-box
    margin-top: 24px

    +desktop
        margin-top: 0px

    &__title
        display: flex
        justify-content: space-between
        align-items: baseline

        &--button
            border-style: none
            border-color: #FF0000

    &__group
        margin-left: -20px
        margin-top: 17px
        border-bottom: 1px solid #DADADA
        background-color: #FBFBFB

        &-mobile
            margin-left: 0
            margin-top: 24px
            border-bottom: 1px solid #DADADA
            background-color: #FBFBFB

        +contrast
            background-color: $contrast-black

    &__text
        &--title
            color: #4A4A4A
            font-family: 'Readex Pro', sans-serif
            font-weight: 500
            font-size: 25px
            // padding-left: 80px
            // font-weight: bold
            // font-size: 0.9rem
            +fullhd
                // font-size: 1.1rem
                // letter-spacing: 0.1px

        &--button
            text-decoration: none
            // color: #005CA9
            font-size: 15px
            // font-weight: 

\:global(.contrast)
    .facets
        &__group
            border-color: #333333
