@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_contrast.sass'
@import '@/styles/_icons.scss'

.header
    position: fixed
    width: 100%
    background: #FFFFFF
    z-index: 999

    +contrast
        background-color: $contrast-black
        color: $contrast-white

    &--mobile
        .header__content
            display: block

    &__container
        content: ''

    &__wrapper
        display: flex
        justify-content: space-between
        height: 125px
        align-items: center

    &__logo
        &--img
            width: 197px

            +fullhd
                width: 257px

    &__content
        height: 100%
        display: flex
        flex-direction: column
        justify-content: space-around

        

        +touch
            display: none

            position: fixed
            width: 100%
            height: calc(100vh - 125px)
            top: 125px
            left: 0
            background: #FFFFFF
            z-index: 999
            border-top: 1px solid #005CA9
            overflow-y: scroll

            +contrast
                background: #000
                border-top: 1px solid $contrast-yellow

    &__upper, &__menu
        &--list
            +desktop
                display: flex
                justify-content: flex-end
                align-items: center

            +touch
                li
                    margin: 0 20px

        &--item
            +touch
                padding: 22px 0

                +contrast
                    button
                        color: $contrast-yellow
    &__menu
        &--list
            +touch
                padding-bottom: 30px
    &__upper
        +desktop
            display: flex
            flex-direction: row
            justify-content: flex-end

        &--list
            li:nth-last-child(2)
                +desktop
                    padding-right: 16px

            li:last-child
                display: flex
                flex-direction: row
                align-items: center
                gap: 16px
                font-weight: 500
                padding: 6px 16px 6px 32px
                position: relative

                p
                    font-size: 0.8rem

                &::before
                    content: ""
                    height: 60%
                    width: 1px
                    background-color: #CCC
                    display: block
                    position: absolute
                    left: 0

                +touch
                    display: none

            +touch
                display: grid
                grid-template-columns: 1fr 1fr
                text-align: center

        +touch
            border-bottom: 1px solid #CCC

    &__menu
        &--divider
            +desktop
                position: relative
                margin-left: 10px
                padding-left: 10px

                &::before
                    height: 70%
                    width: 1px
                    position: absolute
                    left: 0
                    top: 15%
                    background: #CCC
                    display: block
                    content: ''

            +touch
                padding-top: 22px
                position: relative
                top: -1px

                +contrast

                    div
                        color: white !important

        &--item
            +touch
                position: relative

                &::after
                    position: absolute
                    bottom: 0
                    left: 16px
                    width: calc(100% - 32px)
                    height: 1px
                    background: #CCC
                    display: block
                    content: ''

                +contrast

                    div, button
                        color: white !important

            &--modules
                //max-width: 380px

                &::after
                    content: '' !important
                    display: none !important

                &--desc
                    white-space: normal
                    color: #4A4A4A
                    margin: 15px 0
                    max-width: 380px

                    +contrast
                        color: white

                strong
                    font-weight: bolder
                    color: #161615

                    +contrast
                        color: $contrast-yellow

                p:last-of-type
                    text-align: right
                    margin-bottom: 7px

                    &::after
                        @extend .icons
                        content: "\e806"
                        font-size: 0.875em
                        margin-left: 5px
                        position: relative
                        top: -0.1em

                &:hover
                    +contrast
                        strong, p
                            color: black !important

            

        &--item-account
            max-width: 415px
            white-space: break-spaces
            font-family: 'Readex Pro',sans-serif
            padding: 10px 35px

            +touch
                padding: 10px 10px

            &:hover
                background-color: #FFFFFF

                +contrast
                    background-color: $contrast-black

            h4
                font-weight: bold
                margin: 18px 0

            p
                margin: 18px 0
                line-height: 1.444rem

            &__buttons
                display: flex
                justify-content: flex-end
                gap: 20px

            a.MuiButton-link
                color: #FF0000

                +contrast
                    background-color: $contrast-white

        &--item-divider
            border-bottom: 1px dashed #CCC
            margin: 30px 35px

    &__mobile
        +desktop
            display: none

        &__ue
            display: flex
            flex-direction: column-reverse
            align-items: center
            gap: 10px
            font-weight: 500
            margin-left: auto
            padding-right: 15px

            p
                font-size: 0.75rem !important

            +desktop
                display: none

        button
            span
                +contrast
                    background: white !important

    

            
    


.language--active
    position: absolute
    left: 10px
