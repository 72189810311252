@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'

.newsItem
    &__container
        padding-bottom: 50px

    &__search
        padding-top: 125px !important

    &__head
        margin-top: 50px

        +desktop
            margin-top: 25px

        &--title
            @extend .text--heading-main


    &__content
        margin-top: 30px
        display: flex
        flex-direction: row

        +mobile
            flex-direction: column

        &__left
            margin-left: 10px
            width: 25%
            min-width: 25%

            +mobile
                width: auto
                margin-left: 0

            img
                width: 75%

        &--image
            +mobile
                display: flex
                justify-content: center
                align-items: center 

        &__right
            margin-left: 10px

        &__image
            max-width: 255px
            max-height: 208px
            background-color: #F5F5F5
            background-repeat: no-repeat
            background-size: contain

        &--text
            &__title
                margin-top: 20px
                font-size: 20px
                margin-bottom: 25px
                color: #005CA9

            &__date
                margin-top: 20px

                &-value
                    font-weight: bold
                    margin-left: 10px

            &__content
                margin-top: 20px
                @include word-wrap


    &__item
        padding: 25px
        gap: 32px
        //display: grid
        //grid-template-columns: 255px 1fr 200px
