@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import 'styles/_contrast.sass'

.contactform

    &--loading


    &__form
        margin-top: 60px
        display: block

    &__select
        width: 100%
        max-width: 430px

    &__sent
        background: white
        border: 1px solid #000000
        padding: 15px
        margin-top: 30px

    &__input
        width: 100%
        background: transparent
        border: 0
        padding: 5px 10px
        margin: 0
        border-radius: 0
        border-bottom: 1px solid #000000
        font-size: 1rem
        color: #000000
        box-sizing: border-box

        &--small
            max-width: 430px

    &__label
        margin-top: 45px
        color: #005ca9

        span
            color: #4a4a4a

    &__field
        margin-top: 20px

    &__accept
        display: grid
        grid-template-columns: auto 1fr
        align-items: center
        margin-top: 35px

        color: #005ca9

        span
            color: #000

    &__error 
        margin-top: 10px
        color: red

        span
            font-size: 0.8rem

    &__buttons
        display: flex
        justify-content: flex-end
        margin-top: 30px

        button
            margin-left: 15px

    &__notice
        color: #4a4a4a
        font-size: 0.9375rem
        margin-top: 30px

        

    &__text
        &--title
            @extend .text--heading-sub

        &--label

    
        &--accept


\:global(.contrast)
    .contactform
        &__label
            color: $contrast-white

            span
                color: $contrast-white

        &__accept 
            color: $contrast-white
            span
                color: $contrast-white

        &__notice
            color: $contrast-yellow

        &__input
            border-color: $contrast-yellow

        &__adminlist--item
            border-color: $contrast-yellow

            &-bg
                color: $contrast-white
                background: transparent
                border-right-color: $contrast-yellow