@import '@/styles/_breakpoints.sass'
@import '@/styles/_contrast.sass'

.topicsList
	&__content
		background-color: #F5F5F5
		display: flex
		flex-direction: row
		flex-wrap: wrap
		gap: 13px

		+contrast
			background: #000

	&__item
		&__content
			display: flex
			justify-content: space-between
			margin: 15px 0
			padding-right: 10px

	&__container
		padding-bottom: 50px

	&__head
		margin-top: 50px

		+touch
			margin-bottom: 40px

	&__content
		margin: 30px 0

	&__pagination
		text-align: right

	&__search
		padding-top: 125px !important

	&__text
		&--title
			align-items: center
			display: flex
			gap: 25px
			margin-top: 50px

			+touch
				align-items: flex-start

			span
				font-size: 1.75rem

			p
				font-size: 1rem
				line-height: 1rem

			img
				filter: none

			&--bold
				font-weight: 600

		&--info
			margin-top: 10px

			+touch
				line-height: 26px !important

		&--head
			color: inherit
			font-size: 1.2rem
			letter-spacing: 1px

			+contrast
				color: #fff

		&--control
			color: inherit !important
			font-size: 1rem

			+contrast
				color: #fff

		&--link
			margin-right: 38px

			+touch
				display: none

		&--normal
			color: #4A4A4A
			font-size: 1rem

			+contrast
				color: #fff

		&--accent
			color: #161615

			+contrast
				color: #fff

		&--details
			margin-bottom: 3px

			i
				margin-left: -7px

	&__details
		display: flex
		flex-direction: row
		width: 100%

		+touch
			flex-direction: column

		&__content
			display: flex
			flex-direction: column
			height: 100%
			width: 100%

			&__info
				display: flex
				flex-direction: row
				gap: 30px
				margin-bottom: 30px

				+touch
					flex-direction: column
					gap: 5px

			&__description
				display: flex
				flex: 1
				flex-direction: row
				gap: 100px
				justify-content: space-between
				min-height: auto
				line-height: 1.625

				+touch
					flex-direction: column
					gap: 20px

				&__button
					align-self: end

					+touch
						width: 100%

	&__image
		height: 209px
		margin-right: 20px
		object-fit: contain
		width: 255px

		+touch
			margin-bottom: 25px

	&__button
		&--details
			margin-top: 84px
