@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'
    
.financing
    background: #FFFFFF

    +contrast
        background-color: $contrast-black

    &__container 
        padding-top: 45px
        padding-bottom: 45px
        border-top: 1px solid #CCC
    
    &__content
        margin-top: 60px            

    &__footer
        display: grid
        grid-gap: 100px
        justify-content: center
        margin-top: 55px
        align-items: center

        +desktop
            grid-template-columns: repeat(3, auto)

    &__text
        &--header
            @extend .text__heading--home
            text-align: center

        &--content
            @extend .text--body
            text-align: center
            max-width: 800px
            margin: 0 auto
