@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.recomendedList
    &__container
        background-color: #F5F5F5
        padding-bottom: 50px

        +contrast
            background-color: $contrast-black

    &__head
        margin-top: 50px
        margin-bottom: 24px
        display: flex
        align-items: end
        color: #4A4A4A

        +contrast
            color: #fff

        img
            margin-right: 1rem

            +contrast
                filter: invert(100) contrast(100)

        div
            margin-top: 1.5rem
            margin-left: 2.25rem

        p
            margin-top: 1rem
            white-space: nowrap
            margin-bottom: 1rem
            font-size: 1rem

            +touch
                white-space: normal

        em
            font-style: italic
            margin-left: 10px
            vertical-align: bottom

    &__banner
        display: flex
        color: #4A4A4A
        background-color: #F9F9F9
        padding: 1.75rem 3.25rem 1.75rem 1.5rem

        +contrast
            background: #000
            color: #fff

        &__logo
            width: 255px
            height: 208px
            object-fit: contain
            margin-right: 30px

            +mobile
                margin: auto

        p
            margin-top: 1rem
            white-space: nowrap
            margin-bottom: 1rem
            font-size: 1rem

        &__content
            display: flex
            flex-direction: column
            flex: 1

            &__title
                display: flex
                justify-content: space-between

                +mobile
                    align-items: center

            &__description
                width: 75%
                line-height: 1.625rem

            & > p 
                +mobile
                    margin-top: 0

            strong
                font-weight: 600

            +mobile
                margin-top: 15px

        +mobile
            flex-direction: column
            padding: 1.75rem 1.5rem 1.75rem 1.5rem

    &__text
        &--title
            color: #161615
            font-size: 2rem
            font-weight: bold

            +contrast
                color: $contrast-yellow

        &--subtitle
            color: #005CA9
            font-size: 1.25rem !important
            margin-top: 0 !important
            margin-bottom: 1.25rem !important
            font-weight: 500
            letter-spacing: 1px

            +contrast
                color: $contrast-yellow

            +mobile
                display: none

        &--blue
            color: #065FAB
            font-size: inherit

            +contrast
                color: $contrast-yellow

        &--dark
            color: #161615
            font-weight: 600

            +contrast
                color: $contrast-white

        &--aside
            font-size: 0.9375rem

        &--separator
            font-weight: 100
            padding: 0 10px

            +mobile
                display: block
                visibility: hidden
