@import '@/styles/_contrast.sass'
@import '@/styles/_breakpoints.sass'

.notification
    width: 600px
    background: #FFFFFF
    z-index: 1000
    position: fixed
    top: 482px
    left: calc(50% - 300px)
    display: flex
    padding: 10px
    box-shadow: 0 0 5px #000000

    +mobile
        width: 350px
        left: calc(50% - 175px)

    +contrast
        background-color: $contrast-black
        box-shadow: 0 0 5px $contrast-white
    
    &__ok-button
        width: 120px
        flex-grow: 0

    &__text-box
        color: #161615
        align-self: center
        text-align: center
        flex-grow: 1

        +contrast
            color: $contrast-white

        +mobile
            margin-right: 15px
