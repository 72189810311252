@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

$newsEmptyImg: url('../../assets/images/news-empty.svg')

.newsList
    &__search
        padding-top: 125px !important
    &__container
        background-color: #F5F5F5
        padding-bottom: 50px

        +contrast
            background-color: $contrast-black
            color: $contrast-white

    &__head
        margin-top: 50px

        +desktop
            margin-top: 25px

    &__content
        margin-top: 30px
        gap: 20px

    &__item
        padding: 25px
        gap: 32px
        display: grid
        grid-template-columns: 255px 1fr 200px

        +touch
            width: auto

        &--desc
            display: flex
            flex-flow: column
            justify-content: space-between
            width: 480px

            +touch
                width: auto
                gap: 15px

        &--image
            width: 255px
            height: 208px
            background-color: #EBEBEB
            background-repeat: no-repeat
            background-size: contain
            background-image: $newsEmptyImg
            background-position: center

            +mobile
                align-self: center

        &--content
            line-height: 2rem
            margin-left: 15px

            +mobile
                line-height: 1.5625rem
                margin-left: 0

        &--excerpt
            color: #161615

        &--footer
            justify-content: end
            align-self: end

    &__content
        margin-top: 30px

    &__pagination
        text-align: right

    &__text
        @extend .text--heading-main
        margin-top: 50px
        display: flex
        align-items: center
        gap: 25px

        span
            font-size: 1.75rem

        p
            font-size: 1rem
            line-height: 1rem

        &--head
            font-size: 1.25rem
            margin-top: 5px
            margin-bottom: 25px
            color: #005CA9

            +mobile
                margin-bottom: 0

        &--title
            @extend .text--heading-main

        &--date
            line-height: 2rem
            padding-left: 10px

        &--date-prefix
            line-height: 2rem
            font-weight: normal
            margin-bottom: 1.5rem

            +mobile
                margin-bottom: 25px

\:global(.contrast)
    .newsList
        &__item
            &--excerpt
                color: $contrast-white
