@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'

.sidebar
    position: relative

    +desktop
        padding: 0px 35px 10px 0

    +fullhd
        padding: 0px 35px 10px 0

    &__title
        text-transform: uppercase
        font-size: 1rem
        font-weight: 700

        +touch
            margin: 0

        &-container
            display: flex
            justify-content: space-between

            +touch
                background-color: #ECEEEF
                padding: 10px

            &--hidden
                +touch
                    background-color: #296376

                    .sidebar-filters__title,
                    .sidebar-filters__catalogue-collapse
                        color: #FFFFFF

    &__catalogue
        &-hidden
            +touch
                .sidebar-filters__catalogue-list
                    display: none

        &-top
            display: flex
            justify-content: space-between

            +touch
                margin: 12px 10px 0

            &--selected
                +touch
                    background-color: #ECEEEF
                    margin: 0
                    padding: 10px

                .sidebar-filters__catalogue
                    &-title
                        +touch
                            display: none

                    &-selected
                        +touch
                            display: flex

            &--clear
                +touch
                    display: none

        &-collapse
            display: none
            padding: 0

            +touch
                display: inline-block
                font-size: 0.8rem

            i::before
                transform: rotate(180deg)

            &--hidden
                i::before
                    transform: rotate(0deg)

        &-title
            font-size: 0.9rem
            font-weight: 700

        &-selected
            color: #565656
            display: flex
            flex-direction: column
            font-weight: 700
            font-size: 0.8rem
            display: none

            &--span
                color: #4B4B4B
                font-weight: 400

        &-button
            padding: 0
            font-size: 0.8rem

        &-list
            padding: 10px 0 50px
            list-style: none

            +touch
                margin: 0 10px
                padding-bottom: 25px

        &-item
            color: #4B4B4B
            padding-top: 10px

            &--container
                display: flex
                justify-content: space-between

            &--label
                align-items: center
                display: flex

            &--text
                font-size: 0.875rem
                color: #22737B

                &:hover
                    text-decoration: underline

            &--count
                color: #91999F
                font-size: 0.9375rem

            &--input
                width: 14px
                height: 14px
                margin-right: 10px

                &:checked + .sidebar-filters__catalogue-item--text
                    color: #4B4B4B

    &__filters
        display: none
        border-bottom: 1px solid #C0C0C0
        margin-top: 30px
        padding-bottom: 30px
        padding-left: 20px
        color: #161615

        +touch-sec
            display: block

        &__item
            line-height: 1.25
            margin-top: 25px
            color: #4A4A4A

        &__value
            margin-top: 10px

            &--text
                display: inline-block

\:global(.contrast)
    .sidebar
        color: #FFF
