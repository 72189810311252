@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_icons.scss'
@import '@/styles/_contrast.sass'

.sources
    background: #FFFFFF

    +contrast
        background-color: $contrast-black

    &__container 
        padding-top: 45px
        padding-bottom: 45px

    &__footer
        display: flex
        justify-content: center
        margin-top: 45px

    &__list
        > div 
            display: grid
            align-items: center
            justify-content: center

            +desktop
                grid-template-columns: repeat(3, 1fr)

            +widescreen
                grid-template-columns: repeat(5, 1fr)

        div 
            text-align: center


        img 
            width: auto
            max-height: 100px
            margin-bottom: 15px

        a 
            &::after
                display: none

    &__text
        &--header
            @extend .text__heading--home
            text-align: center
