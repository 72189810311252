.slick-prev
    left: 25px
    z-index: 998

.slick-next
    right: 25px
    z-index: 998

.slick-prev::before, .slick-next::before
    opacity: 1
    color: #808080
